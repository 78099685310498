import { Grid, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import BrokenLink from '../../assets/BrokenLink';
import FullScreenPage from './FullScreenPage';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    padding: theme.spacing(5),
    minHeight: 450,
  },
}));

function BrokenLinkIcon() {
  const theme = useTheme();
  return <BrokenLink fill={theme.palette.secondary.main} />;
}

function PageMessage({
  header,
  body,
  actions,
  icon = <BrokenLinkIcon />,
  logo = true,
}) {
  const classes = useStyles();

  return (
    <FullScreenPage logo={logo} width={650}>
      <Grid
        className={classes.root}
        container
        spacing={4}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {icon && <Grid item>{icon}</Grid>}

        {header && (
          <Grid item>
            <Typography variant="h3" color="primary">
              {header}
            </Typography>
          </Grid>
        )}

        {body && (
          <Grid item>
            <Typography variant="body1" color="primary">
              {body}
            </Typography>
          </Grid>
        )}

        {actions && <Grid item>{actions}</Grid>}
      </Grid>
    </FullScreenPage>
  );
}

export default PageMessage;
