export default function BrokenLink({
  width = 197,
  height = 83,
  fill = '#FFF',
}) {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M99.34 42.515l-4.07-7.39 6.739-7.915-4.659-8.46 6.07-7.126-4.663-8.461 2.126-2.494L196 9.943l-6.439 62.34-90.864-8.855-88.014 7.96-6.016-62.78L92.368.669l-1.604 2.86 6.28 7.484-4.58 8.18 6.287 7.483-5.09 9.078 5.678 6.76zm1.046 1.9l-.159.284.191-.225-.032-.058z"
        fill={fill}
      />
    </svg>
  );
}
