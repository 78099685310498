import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Button, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SUPPORT_EMAIL } from 'src/environment';
import PageMessage from 'src/unauthorized/layouts/PageMessage';

function Custom404() {
  const [href, setHref] = useState();

  useEffect(() => {
    setHref(
      `mailto:${SUPPORT_EMAIL}?subject=Invalid%20link&body=Invalid%20link%3A%20${encodeURIComponent(
        window.location,
      )}`,
    );
  }, []);

  return (
    <PageMessage
      logo={false}
      header={<FormattedMessage defaultMessage="Page not found!" />}
      body={
        <FormattedMessage defaultMessage="If you would like to report this issue, please send us feedback (use the button below this text) and describe in detail how we can reproduce it. Thank you!" />
      }
      actions={
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Button color="primary" variant="contained" size="large" href="/">
            <FormattedMessage defaultMessage="Go to homepage" />
          </Button>

          <Button
            color="primary"
            variant="outlined"
            size="large"
            disabled={!href}
            href={href}
            endIcon={<MailOutlineIcon />}
          >
            <FormattedMessage defaultMessage="Send feedback" />
          </Button>
        </Stack>
      }
    />
  );
}

export default Custom404;
